
body, html{
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  background: #f1f1f1;
  overflow-x: hidden;
  background-color: #f4f4f4;
}

.empty_paragrapgh{
  width: 100%;
  height: 65px;
  background-color: #fff;
  position: relative;
  /* top: 0; */
  margin: 0;
}

/* General Styles */

.point{
  cursor: pointer;
}

.bd{
  font-weight: bold;
}

.wyt{
  color: #fff;
}