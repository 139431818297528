
.nav_bar{
    width: 100%;
    height: 65px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 50;
    /* border-bottom: 1px solid #f3f3f3; */
    box-shadow: 2px 3px 3px #f3f3f3;
}

.app_title_cont{
    margin: 0 0 0 4.5rem;
}

.logo_title{
    font-weight: bolder;
    text-decoration: none;
    font-size: 22px;
    color: #833AB4;
    font-family: 'Rajdhani', sans-serif;
}

.menu_bbar{ display: none; }

.menu_icon_{
    font-weight: bolder;
    font-size: 28px;
    cursor: pointer;
}

.nav_bar_ul{ list-style: none; }

.nav_bar_list{
    display: inline;
    padding: 11px;
}

.nav_bar_link{
    color: #555;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 14.5px;
    transition: all .13s linear;
    font-weight: bold;
}

.nav_bar_link:hover{
    color: #000;
}

.acct_nav_bar_cont{
    margin-right: 4.5rem;
}

.nav_bar_log_in{
    font-weight: bold;
}

.nav_bar_get_started{
    background-color: #833AB4 !important;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    /* font-weight: bold; */
    /* border: 1px solid purple; */
}

.nav_bar_get_started:hover{
    color: #fff;
    /* font-weight: normal; */
    transition: all .13s linear;
    box-shadow: 2px 2px 3px #aaa;
}

.nav_bar_get_started:active{
    box-shadow: none;
}

/* mobile view   743px  */
@media (max-width: 743px) {
    
    .mid_nav_bar_cont, 
    .acct_nav_bar_cont{
        display: none;
    }

    .menu_bbar{
        display: block;
        margin: 0 1.9em 0 0;
        cursor: pointer;
        font-size: 23px;
        font-weight: bolder;
        color: #222;
        transition: all .11s linear;
        z-index: 10;
    }

    .menu_bbar:active{
        transform: scale(1.1);
    }

    .app_title_cont{
        margin: 0 0 0 2em;
    }

}