
.fter_comp{
    width: 100%;
    height: auto;
    position: relative;
    background-color: #fff;
    margin-top: 5em;
    bottom: 0;
    padding: 10px;
    border-top-left-radius: 90px;
    border-top-right-radius: 90px;
}

.ul_futa_cnt_{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}

.futa_desc_p{
    line-height: 1.3em;
    font-size: 14px;
}

.futa_ul{ list-style: none; }

.futa_ul > li{
    padding: 4px;
}

.futa_anko{
    color: #333;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    transition: all .1s linear;
}

.futa_anko:hover{
    color: #000;
}

.futa_anko:active{
    text-decoration: underline;
}

.futa_hh22{
    font-family: 'Rajdhani', sans-serif;
}

.futa_hr_{
    width: 88%;
}

.lst_lst_{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.lst_lst_ > p{
    font-size: 13px;
    margin: 2em;
    cursor: pointer;
}

@media (max-width: 700px) {
    .fter_comp{
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
}