
.mob_sd_nv_br_cont{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 51; 
    background-color: rgba(26, 54, 80, 0.7);
}
.nav_art{
    background-color: #fff;
    width: 375px;
    height: 65%;
    position: absolute;
    right: 0;
    box-sizing: border-box;
    transition: all 0.5s ease 0s;
}

.close_mn_bar{
    cursor: pointer;
    /* font-size: 22px; */
    color: #555;
    transition: all .13s ease;
}

.close_mn_bar:hover{
    color: #000;
}

.close_mn_bar:active{
    transform: scale(1.08);
}

.mbmbmbmb{
    /* border: 1px solid #000; */
    margin-top: 4cm;
}

.nv_br_clz{
    /* font-size: 52px; */
    /* color: red; */
}

.ht_nv_br_{
    /* border: 1px solid #000; */
    height: 70%;
}
.nv_mb__fx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    /* background-color: #ccc; */
    margin: 8px 0 0 0;
}

.nv_mb__fx:hover{
    /* background-color: #eaeaea; */
    background-color: whitesmoke;
}

.nv_h3_{
    margin-left: 2.5rem;
    font-family: 'Rajdhani', sans-serif;
    color: #833AB4;
    font-size: 19px;

    font-weight: bolder;
    text-decoration: none;
    font-size: 22px;
    color: #833AB4;
    font-family: 'Rajdhani', sans-serif;
}

.nv_ppp{
    /* color: red; */
    color: #444;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}

.close_mn_bar{
    margin-right: 2.6rem;
    font-size: 15px;
}

.mb_nv_br_btn{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;
    align-items: center;
}

.nv_br_bbb{
    padding: 11px 42px ;
    font-weight: bold;
    font-size: 15px;
    margin: 10px;
    outline: 0;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: all .1.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.nv_br_bbb:active{
    transform: scale(1.03);
}

.mb_nv_gn_up_{
    color: #fff;
    background-color: #833AB4;
}

@media (max-width: 743px) {
    .nav_art{
        width: 100%;
    }
}

@media (min-width: 744px) {
    .mob_sd_nv_br_cont{
        display: none;
    }
}